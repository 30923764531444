.optionButtons {
    flex-grow: 1;
    padding: 2rem 2rem;
    justify-content: space-evenly;
    height: 50vh;
}

.optionButtons .MuiIconButton-root {
    background-color:white;
    box-shadow: 0px 10px 53px 0px rgba(0,0,0,0.3) !important;
}

.optionIcon {
    padding: 1.5vw !important;
}

.option {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.caption {
    text-align: center;
    margin-top: 1rem;
}