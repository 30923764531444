.result {
    border-radius: 10px;
    height: 100px;
    width: 80vh;
    max-width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 10px 53px 0px rgba(0,0,0,0.3) !important;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    margin-top: 2rem;
    color: white;
    font-weight: bolder;
    background-position: center;
    background-size: cover;
}

.result:hover {
    cursor: pointer;
}

.searchResult {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}