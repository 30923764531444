.search {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
}

.swipeButtons .MuiIconButton-root {
    background-color:white;
    box-shadow: 0px 10px 53px 0px rgba(0,0,0,0.3) !important;
}

.swipeButtons__left {
    padding: 2vw !important;
    color: #ec5e6f !important;
}

.swipeButtons__star {
    padding: 2vw !important;
    color: #62b4f9 !important;
}

.swipeButtons__right {
    padding: 2vw !important;
    color: #76e2b3 !important;
}
